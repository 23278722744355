import React, { useState, useRef, useEffect } from "react"
import Blocks from "../_Blocks"
import BlockWrapper from "../../technical/BlockWrapper"

import { CaretRight24 } from "@carbon/icons-react"

const AccordionFolderBlock = ({ block }) => {
  const [activeAccordionState, setActiveAccordionState] = useState(null)
  const refContainers = useRef([])

  const refContainersCollection = (el) => {
    if (el && !refContainers.current.includes(el)) {
      refContainers.current.push(el)
    }
  }

  // Workaround: Verhindert falsche Start-Höhe des Contents, weil Bild zu lange zum Laden braucht. => Refactoring
  useEffect(() => {
    setTimeout(() => {
      setActiveAccordionState(null)
    }, 1000)
  }, [])

  const toggleAccordion = (index) => {
    const newActiveAccordionState =
      activeAccordionState !== index ? index : null
    setActiveAccordionState(newActiveAccordionState)
  }

  return (
    <BlockWrapper block={block} showHeadline="true">
      <div className="mt-8">
        {block.folder_items?.length > 0 &&
          block.folder_items.map((item, index) => (
            <div key={index}>
              <button
                className={`w-full group focus:outline-none text-left py-4 border-gray-100 border-t-2 font-bold px-grid ${
                  activeAccordionState === index ? "" : ""
                } ${index > 0 ? "-mt-0.5" : ""}`}
                onClick={() => toggleAccordion(index)}
              >
                <CaretRight24
                  className={`inline-block -mt-1 mr-2 transition-transform group-hover:text-primary duration-500 transform ${
                    activeAccordionState === index ? " rotate-90" : ""
                  }`}
                />
                {item.title}
              </button>
              <div
                className="overflow-hidden transition-all duration-500 "
                ref={refContainersCollection}
                style={{
                  height: `${
                    activeAccordionState === index
                      ? `${refContainers?.current[index]?.scrollHeight}px`
                      : "0px"
                  }`,
                }}
              >
                {item.content?.length > 0 &&
                  item.content.map((content) =>
                    React.createElement(Blocks(content.component), {
                      key: content._uid,
                      block: content,
                      type: content.component,
                    })
                  )}
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default AccordionFolderBlock
