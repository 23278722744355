import React from "react"
import AccordionFolderBlock from "./FolderBlock/AccordionFolderBlock"
import TabFolderBlock from "./FolderBlock/TabFolderBlock"

const FolderBlock = ({ block }) => {
  if (block.view === "tabs") {
    return <TabFolderBlock block={block} />
  } else {
    return <AccordionFolderBlock block={block} />
  }
}

export default FolderBlock
